import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import { filter, Subject, Subscription, takeUntil } from 'rxjs';

import DateBox from 'devextreme/ui/date_box';
import DropDownBox from 'devextreme/ui/drop_down_box';
import NumberBox from 'devextreme/ui/number_box';
import Scrollable from 'devextreme/ui/scroll_view/ui.scrollable';
import SelectBox from 'devextreme/ui/select_box';
import TextArea from 'devextreme/ui/text_area';
import TextBox from 'devextreme/ui/text_box';
import { TranslateService } from '@ngx-translate/core';
import { locale } from 'devextreme/localization';
import moment from 'moment';
import { LocalStorageService } from './shared/local-storage.service';
import { Identifier } from './shared/models/storage.models';
import { Store, select } from '@ngrx/store';
import { State } from './store';
import { getContentLoadingState } from './store/ui/ui.selectors';
import { registerLocaleData } from '@angular/common';
import localeGerm from '@angular/common/locales/de';
import localeEnCA from '@angular/common/locales/en-CA';
import localeSpan from '@angular/common/locales/es-MX';
import localeFrCaExtra from '@angular/common/locales/extra/fr-CA';
import localeFr from '@angular/common/locales/fr';
import localeFrCA from '@angular/common/locales/fr-CA';
import { HeaderComponent } from './header/header.component';
import { RouterOutlet } from '@angular/router';
import {
  EventMessage,
  EventType,
  InteractionStatus,
} from '@azure/msal-browser';
import { MsalConfig } from './auth/msal.config';
import { GraphService, SSOLoginStatus } from './auth/graph.service';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';

@Component({
  standalone: true,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [HeaderComponent, RouterOutlet],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  @HostBinding('class') classes = '';
  title = 'Central Office Web';
  loadSub?: Subscription | null = null;
  private subscription: Subscription = new Subscription();

  constructor(
    private translateService: TranslateService,
    private localStorageService: LocalStorageService,
    private graphService: GraphService,
    @Optional() private msalService: MsalService,
    @Optional() private msalBroadcastService: MsalBroadcastService,


    private ref: ChangeDetectorRef,
    private store: Store<State>
  ) {
    registerLocaleData(localeFr);
    registerLocaleData(localeFrCA, localeFrCaExtra);
    registerLocaleData(localeEnCA);
    registerLocaleData(localeSpan);
    registerLocaleData(localeGerm);

    translateService.addLangs(['en-CA', 'fr-CA']);

    NumberBox.defaultOptions({
      options: {
        stylingMode: 'outlined', //   'underlined'
      },
    });
    TextArea.defaultOptions({
      options: {
        stylingMode: 'outlined', //   'underlined'
      },
    });
    DateBox.defaultOptions({
      options: {
        stylingMode: 'outlined', //   'underlined'
        calendarOptions: { firstDayOfWeek: 0 },
      },
    });
    SelectBox.defaultOptions({
      options: {
        stylingMode: 'outlined', //   'underlined'
      },
    });
    DropDownBox.defaultOptions({
      options: {
        stylingMode: 'outlined', //   'underlined'
      },
    });
    TextBox.defaultOptions({
      options: {
        stylingMode: 'outlined', //  'filled', 'underlined'
      },
    });
    Scrollable.defaultOptions({
      options: {
        showScrollbar: 'onHover',
      },
    });
  }

  private subscribeToLanguageLocalStorage(): void {
    this.subscription.add(
      this.localStorageService
        .getStorageItem(Identifier.TYPE_LANGUAGE)
        .subscribe((lang) => this.setLanguage(lang))
    );
  }

  private setLanguage(lang: string | null): void {
    let curLang = 'en-CA';
    if (lang && lang !== '') {
      curLang = lang;
    }
    this.translateService.setDefaultLang(curLang);
    this.translateService.use(curLang);
    locale(curLang);
    switch (curLang) {
      case 'fr-CA':
        moment.locale('fr');
        break;
      default:
        moment.locale('en');
        break;
    }
    this.ref.markForCheck();
  }

  ngOnInit() {
    this.subscribeToLanguageLocalStorage();

    this.subscription.add(
      this.store
        .pipe(select(getContentLoadingState))
        .subscribe((isContentLoading) => {
          Promise.resolve().then(() => {
            this.classes = isContentLoading ? 'loader-overlay loading' : '';
            this.ref.detectChanges();
          });
        })
    );


    if (MsalConfig.isEnabled) {
      this.msalService.handleRedirectObservable().subscribe();
      this.setLoginDisplay();
      this.msalService.instance.enableAccountStorageEvents();

      this.msalBroadcastService.msalSubject$
        .pipe(
          filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
        )
        .subscribe((result: any) => {
          if (this.msalService.instance.getAllAccounts().length === 0) {
            this.graphService.ssoAuthenticated$.next(SSOLoginStatus.Logout);
          } else {
            this.setLoginDisplay();
            if (result?.payload?.account) {
              this.msalService.instance.setActiveAccount(result.payload.account);
              MsalConfig.account = result.payload.account;
              this.graphService.ssoAuthenticated$.next(SSOLoginStatus.Login);
            }
          }
        });


      this.msalBroadcastService.inProgress$
        .pipe(
          filter((status: InteractionStatus) => status === InteractionStatus.None),
          takeUntil(this._destroying$)
        )
        .subscribe(() => {
          this.setLoginDisplay();
          this.checkAndSetActiveAccount();
        });
    }

  }
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  setLoginDisplay(): void {
    this.loginDisplay = this.msalService.instance.getAllAccounts().length > 0;
  }
  checkAndSetActiveAccount(): void {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    const activeAccount = this.msalService.instance.getActiveAccount();

    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      const accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
      MsalConfig.account = accounts[0] as any;
      this.graphService.ssoAuthenticated$.next(SSOLoginStatus.Login);
    }
  }
  ngAfterViewInit(): void {
    this.subscription.add(
      this.localStorageService
        .getStorageItem(Identifier.TYPE_IS_SSO_LOGIN)
        .subscribe((sso_login) => {
          if (sso_login === 'true') {
            setTimeout(() => {
              this.graphService.acquireToken();
              //this.loginPopup();
            }, 1000);
          }
        })
    );
  }

  ngOnDestroy() {
    if (this.loadSub) this.loadSub.unsubscribe();
    this.loadSub = null;
  }
}
