import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { Store } from '@ngrx/store';
import { selectAuthenticatedUser } from '../store/user/user.selectors';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '../shared/local-storage.service';
import { LanguagesEnum } from '../shared/models/corr-language.enum';
import { getLanguageStatus } from '../shared/language/utils/language.utils';
import { Identifier } from '../shared/models/storage.models';
import { AsyncPipe } from '@angular/common';
import { InitialStartupService } from '../auth/initial-startup.service';
import { GraphService, SSOLoginStatus } from '../auth/graph.service';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  standalone: true,
  imports: [TranslateModule, AsyncPipe],
})
export class HeaderComponent implements OnInit {
  isAuthenticated$: Observable<boolean> | undefined | null = null;
  languages = [
    { id: LanguagesEnum.EN_CA, name: 'English' },
    { id: LanguagesEnum.FR_CA, name: 'French' },
  ];
  currentLanguage?: string;
  languagesEnum = LanguagesEnum;

  languageStatus = getLanguageStatus();

  constructor(
    private authService: InitialStartupService,
    private store: Store<any>,
    public translateService: TranslateService,
    private localStorageService: LocalStorageService,
    private graphService: GraphService,
    private msalService: MsalService
  ) { }

  ngOnInit() {
    this.isAuthenticated$ = this.store.select(selectAuthenticatedUser);

    this.currentLanguage =
      this.localStorageService.getStorageItem(Identifier.TYPE_LANGUAGE).value ??
      this.translateService.getDefaultLang();
  }

  onLogout() {
    const sso_login = localStorage.getItem(Identifier.TYPE_IS_SSO_LOGIN);
    if (sso_login === 'true') {
      this.logoutADUser();
    } else {
      this.authService.logout();
    }
  }
  logoutADUser(): void {
    this.graphService.ssoAuthenticated$.next(SSOLoginStatus.Logout);
    const endSessionRequest = {
      mainWindowRedirectUri: '/'
    };
    this.msalService.logoutPopup(endSessionRequest);
    setTimeout(() => {
      this.authService.logout();
    });
  }
  onLanguageChange(lng: string) {
    this.localStorageService.setStorageItem(Identifier.TYPE_LANGUAGE, lng);
    this.currentLanguage = lng;
  }
}
